import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SidebarService {
  toggled = false;
  _hasBackgroundImage = true;
  menus = [
    {
      title: 'general',
      type: 'header'
    },
    {
      title: 'Dashboard',
      icon: 'fa fa-tachometer-alt',
      active: false,
      type: 'simple',
      badge: {
        text: 'New ',
        class: 'badge-warning'
      },
      path : '/' 
    },
    {
      title: 'Question Paper',
      icon: 'fa fa-shopping-cart',
      active: false,
      type: 'dropdown',
      badge: {
        text: '3',
        class: 'badge-danger'
      },
      submenus: [
        {
          title: 'Infosys',
          path : '/placement-papers/infosys'
        },
        {
          title: 'Wipro',
          path : '/placement-papers/wipro'
        },
        {
          title: 'TCS',
          path : '/placement-papers/tcs'
        },
        {
          title: 'Accenture',
          path : '/placement-papers/accenture'
        },
        {
          title: 'Capgemini',
          path : '/placement-papers/capgemini'
        },
        {
          title: 'CTS',
          path : '/placement-papers/cts'
        },
        {
          title : 'IBM',
          path : '/placement-papers/ibm'
        },
        {
          title : 'Tech Mahindra',
          path : '/placement-papers/techmahindra'
        },
        {
          title : 'MindTree',
          path : '/placement-papers/mindtree'
        },
        {
          title : 'HCL',
          path : '/placement-papers/hcl'
        },
      ]
    },
    {
      title: 'Aptitude Questions',
      icon: 'fa fa-globe',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'Infosys',
          path : '/aptitude-questions/infosys'
        },
        {
          title: 'Wipro',
          path : '/aptitude-questions/wipro'
        },
        {
          title: 'TCS',
          path : '/aptitude-questions/tcs'
        },
        {
          title: 'Accenture',
          path : '/aptitude-questions/accenture'
        },
        {
          title: 'Capgemini',
          path : '/aptitude-questions/capgemini'
        },
        {
          title: 'CTS',
          path : '/aptitude-questions/cts'
        },
        // {
        //   title : 'IBM',
        //   path : '/aptitude-questions/ibm'
        // },
        // {
        //   title : 'Tech Mahindra',
        //   path : '/aptitude-questions/techmahindra'
        // },
        // {
        //   title : 'MindTree',
        //   path : '/aptitude-questions/mindtree'
        // },
        // {
        //   title : 'HCL',
        //   path : '/aptitude-questions/hcl'
        // },
      ]
    },
    {
      title: 'Interview Questions',
      icon: 'fa fa-snowflake',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'Infosys',
          path : '/interview-questions/infosys'
        },
        {
          title: 'Wipro',
          path : '/interview-questions/wipro'
        },
        {
          title: 'TCS',
          path : '/interview-questions/tcs'
        },
        {
          title: 'Accenture',
          path : '/interview-questions/accenture'
        },
        {
          title: 'Capgemini',
          path : '/interview-questions/capgemini'
        },
        {
          title: 'CTS',
          path : '/interview-questions/cts'
        },
        // {
        //   title : 'IBM',
        //   path : '/interview-questions/ibm'
        // },
        // {
        //   title : 'Tech Mahindra',
        //   path : '/interview-questions/techmahindra'
        // },
        // {
        //   title : 'MindTree',
        //   path : '/interview-questions/mindtree'
        // },
        // {
        //   title : 'HCL',
        //   path : '/interview-questions/hcl'
        // },
      ]
    },
    {
      title: 'Download Resume',
      icon: 'fa fa-download',
      active: false,
      path : '/resume-template-format/download',
      type: 'simple',
    },
    {
      title: 'Support',
      type: 'header'
    },
    {
      title: 'Documentation',
      icon: 'fa fa-book',
      active: false,
      type: 'simple',
      path : '/',
      badge: {
        text: 'Beta',
        class: 'badge-primary'
      },
    },
    {
      title: 'About Us',
      icon: 'fa fa-comment',
      path : '/about-us',
      active: false,
      type: 'simple'
    },
    {
      title: 'Contact',
      icon: 'fa fa-envelope',
      path : '/contact',
      active: false,
      type: 'simple'
    }
  ];
  pathName;
  constructor() { 
    this.pathName = new BehaviorSubject(''); 
    if (document.documentElement.clientWidth <= 1024)  {
      this.toggled = true;
    }
  }

  toggle() {
    this.toggled = ! this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  getMenuList() {
    return this.menus;
  }

  get hasBackgroundImage() {
    return this._hasBackgroundImage;
  }

  set hasBackgroundImage(hasBackgroundImage) {
    this._hasBackgroundImage = hasBackgroundImage;
  }
}
