import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private meta: Meta,
    private head: Title, public sidebarservice: SidebarService) { 
      this.setSeo();
    }

  ngOnInit() {
  }

  setSeo() {
    this.head.setTitle('Contact Us - Placement Master');
    this.meta.updateTag({ name: 'description', content: "Our Support team is resolve your queries. Contact us via email ,twitter and facebook"});
    this.meta.updateTag({ name: 'keywords', content: 'Placement Master Contact Us'});
    this.meta.updateTag({ name: 'share_url', content: window.location.href });
  }
}
