import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { QuestionpaperComponent} from './question-paper/questionpaper.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResumeDownloadComponent} from './resume-download/resume-download.component';

import { AptitudeQuestionsComponent } from './aptitude-questions/aptitude-questions.component';
import { InterviewQuestionsComponent } from './interview-questions/interview-questions.component';
import { ContactComponent } from './contact/contact.component';
import { AboutUsComponent } from './about-us/about-us.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    component: DashboardComponent,
    pathMatch: 'full',
  },
  {
    path: 'placement-papers/:name',
    component: QuestionpaperComponent
  },
  {
    path: 'aptitude-questions/:name',
    component: AptitudeQuestionsComponent
  },
  {
    path: 'interview-questions/:name',
    component: InterviewQuestionsComponent
  },
  {
    path: 'resume-template-format/download',
    component: ResumeDownloadComponent
  },
  // {
  //   path: '',
  //   component: DashboardComponent
  // },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
