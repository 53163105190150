import { Component, OnInit, DoCheck, OnDestroy } from '@angular/core';

import { ProductService } from '../product.service';
import { Title, Meta } from '@angular/platform-browser';
import { SidebarService } from '../sidebar/sidebar.service';

// declare var Instamojo: any;
@Component({
  selector: 'app-questionpaper',
  templateUrl: './questionpaper.component.html',
  styleUrls: ['./questionpaper.component.css']
})

export class QuestionpaperComponent implements OnInit , DoCheck , OnDestroy {


  image = '../../assets/img/angular.png';
  id = "5913684";
  description = ``;
  keyword = ``;
  commonName;
  commomService;
  paymentURL = '';
  name = '';
  title = '';
  companyList = [];
  currentData;
  loader = false;
  apiService;
  productList;
  domain = `https://placementmaster.in`;

  companyData = {
    tcs: {
      url: "https://placementmentor.myinstamojo.com/product/tcs-aptitude-questions-study-materials/",
      id: "5913684"
    },
    accenture: {
      url: "https://placementmentor.myinstamojo.com/product/accenture-aptitude-questions-study-materials/",
      id: "5913641"
    },
    cts: {
      url: "https://placementmentor.myinstamojo.com/product/cts-aptitude-questions-study-materials/",
      id: "5913682"
    },
    capgemini: {
      url: "https://placementmentor.myinstamojo.com/product/capgemini-aptitude-questions-study-materials/",
      id: "5913685"
    },
    techMahindra: {
      url: "https://placementmentor.myinstamojo.com/product/tech-mahindra-aptitude-questions-study-mater/",
      id: "5913679"
    },
    wipro: {
      url: "https://placementmentor.myinstamojo.com/product/wipro-aptitude-questions/",
      id: "5913678"
    },
    infosys: {
      url: "https://placementmentor.myinstamojo.com/product/infosys-aptitude-questions-study-materials/",
      id: "5913640"
    }
  };
  currentCompany="";
  
  constructor(private productService: ProductService, private meta: Meta,
    private head: Title, public sidebarservice: SidebarService) {
    this.setSeo();
    this.name = window.location.pathname.split('/')[2];
    this.checkCurrentData();
  }

  ngOnInit() {
    this.loadScript();
    this.paymentURL = `https://www.instamojo.com/placementmaster/amcat-materials-for-amcat-exam-preparation/`
    this.currentCompany = this.name; // 'tcs', 'accenture', etc.
  }
  capitalizeFirstLetter(string) {
    if (string) { 
      string = string.charAt(0).toUpperCase() + string.slice(1);
      string = this.captialName(string);
      return string;
    }
  }
  loadScript() {
    const script = document.createElement('script');
    script.src = 'https://manage.instamojo.com/assets/js/pay_button/button.min.js';
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => {
      console.log('Instamojo payment script loaded!');
    };
    document.body.appendChild(script);
  }
  setSeo() {
    this.name = window.location.pathname.split('/')[2];
    this.title = this.capitalizeFirstLetter(window.location.pathname.split('/')[2]) + ` Placement Papers | Placement Master`;
    this.commonName = window.location.pathname.split('/')[2] + " " + window.location.pathname.split('/')[1].replace('-', ' ');
    this.description = `Collection of all the previous year placement papers conducted by ${this.capitalizeFirstLetter(this.name)}. It includes all questions from each section in the interview by ${this.capitalizeFirstLetter(this.name)}`;
    this.head.setTitle(this.title);
    this.meta.updateTag({ name: 'description', content: this.description });
    this.meta.updateTag({ name: 'og:description', content: this.description });
    this.meta.updateTag({ name: 'og:title', content: this.title });
    this.meta.updateTag({ name: 'twitter:title', content: this.title });
    this.meta.updateTag({ name: 'share_url', content: 
    `${this.domain}${window.location.pathname}` });
    this.meta.updateTag({ name: 'og:url', content: `${this.domain}${window.location.pathname}`});
    this.meta.updateTag({ name: 'keywords', content: this.commonName });
  }
  ngDoCheck() {
   this.commomService = this.sidebarservice.pathName.subscribe(val => {
      if (val) {
        this.setSeo();
      }
    });
  }
  ngAfterViewInit() {
    this.commomService = this.sidebarservice.pathName.subscribe(val => {
      if (val) {
        setTimeout(() => {
            if (window.location.pathname.includes('/placement-papers')) {
              this.checkCurrentData();
            }
        }, 200);
      }
    });
  }
  ngOnDestroy() {
    this.commomService.unsubscribe();
    if (this.apiService) this.apiService.unsubscribe();
    
  }
  buy() {
    window.open('https://placementmaster.myinstamojo.com/product/1021732/download-service-company-question-paper-coll/');
    // Instamojo.open(url); 
  }

  checkCurrentData() {

    if (this.name === 'mindtree') this.name = 'Mindtree';
    if (this.name === 'techmahindra') this.name = 'TechMahindra';
    const id = `placement_master_${this.name}`
    this.getinterviewQuestion(id);
  }
  getinterviewQuestion(id) {
    this.loader = true;
    this.productList = null;
    this.apiService = this.productService.getAptitudecommonQuestion(id).subscribe((res: any) => {
      let input = (res.data && typeof res.data === 'string'&& JSON.parse(res.data)) || res.data;
      this.productList = input.data;
      this.loader = false;
    }, (err) => {
      this.loader = false;
    });
  }

  captialName(name) {
    return name.length <= 3 ? name.toUpperCase() : name;
  }
}
