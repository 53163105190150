import { Component, OnInit } from '@angular/core';

import { ProductService } from '../product.service';
import { Title, Meta } from '@angular/platform-browser';

declare var Instamojo: any;
@Component({
  selector: 'app-resume-download',
  templateUrl: './resume-download.component.html',
  styleUrls: ['./resume-download.component.css']
})
export class ResumeDownloadComponent implements OnInit {

  image = '../../assets/img/angular.png';

  description = `Download Resume Template in PDF Format. Make your Resume stand unique from others by Downloading our Resume Template to make your first impression worth it!`;

  keyword = `resume format download , resume format word, free resume templates , cv format download pdf`;

  title = `Resume Format Download | Placement Master`;
  domain = `https://placementmaster.in`;
  
  resumeList = [];
  constructor(private productService: ProductService, private meta: Meta,
    private head: Title) {
    this.head.setTitle(this.title);
    this.meta.updateTag({ name: 'description', content: this.description });
    this.meta.updateTag({ name: 'keywords', content: this.keyword });
    this.meta.updateTag({ name: 'share_url', content: 
    `${this.domain}${window.location.pathname}` });
    this.meta.updateTag({ name: 'og:description', content: this.description });
    this.meta.updateTag({ name: 'og:title', content: this.title });
    this.meta.updateTag({ name: 'twitter:title', content: this.title });
    this.meta.updateTag({ name: 'og:url', content: `${this.domain}${window.location.pathname}`});
  }

  ngOnInit() {
    this.resumeList = [
      {
        image_url: 'https://media.instamojo.com/imgs/5e676caf9a5e499982226cf36203af92.jpg',
        payment_url: 'https://www.instamojo.com/placementmaster/download-resume-template-format-1/',
        size: '524 KB',
      },
      {
        image_url: 'https://media.instamojo.com/imgs/485863a4bdc04c94bd8491b4e9e712f0.jpg',
        payment_url: 'https://www.instamojo.com/placementmaster/resume-2-35227/',
        size: '282 KB',
      },
      {
        image_url: 'https://media.instamojo.com/imgs/102af9f02cb242fa9be9ffb929494092.jpg',
        payment_url: 'https://www.instamojo.com/placementmaster/sample-resume-template-format-3-b2842/',
        size: '683 KB',
      },
      {
        image_url: 'https://media.instamojo.com/imgs/875f5ede48b742df9ebb28ca81db7850.jpg',
        payment_url: 'https://www.instamojo.com/placementmaster/sample-resume-template-format-4/',
        size: '330 KB',
      },
      {
        image_url: 'https://media.instamojo.com/imgs/16123e11456944bebcf759cba53cde62.jpg',
        payment_url: 'https://www.instamojo.com/placementmaster/resume-6/',
        size: '347 KB',
      },
      {
        image_url: 'https://media.instamojo.com/imgs/113d27e2cd9441af92b039133aa6d11c.jpg',
        payment_url: 'https://www.instamojo.com/placementmaster/resume-7-08a73/',
        size: '475 KB',
      },
      {
        image_url: 'https://media.instamojo.com/imgs/bee162b9c4c34334accf43c1b494a2cc.jpg',
        payment_url: 'https://www.instamojo.com/placementmaster/resume-9-84f14/',
        size: '555 KB',
      },
      {
        image_url: 'https://media.instamojo.com/imgs/4470be701c414865bba3b15a37e15119.jpg',
        payment_url: 'https://www.instamojo.com/placementmaster/resume-10-49e1d/',
        size: '288 KB',
      },
      {
        image_url: 'https://media.instamojo.com/imgs/3cce38f4d8dd4d50973b53c61d2d9f52.jpg',
        payment_url: 'https://www.instamojo.com/placementmaster/resume-11-37c62/',
        size: '291 KB',
      },
      {
        image_url: 'https://media.instamojo.com/imgs/a179386caa31400fa38c5e1e629f1d99.jpg',
        payment_url: 'https://www.instamojo.com/placementmaster/resume-12-445ee/',
        size: '658 KB',
      },
      {
        image_url: 'https://media.instamojo.com/imgs/8dbf444d0bf441e8b2dea77450534dd5.jpg',
        payment_url: 'https://www.instamojo.com/placementmaster/resume-13-7890e/',
        size: '290 KB',
      },
      {
        image_url: 'https://media.instamojo.com/imgs/c31935938fd941558ee7770f4269677e.jpg',
        payment_url: 'https://www.instamojo.com/placementmaster/resume-14-de0d5/',
        size: '200 KB',
      },
      {
        image_url: 'https://media.instamojo.com/imgs/5262dbe2b2a4480889c7979afc35167d.jpg',
        payment_url: 'https://www.instamojo.com/placementmaster/resume-17-77d77/',
        size: '304 KB',
      },
    ];
  }
  buy(url) {
    Instamojo.open(url); 
  }

}
