import { Component, OnInit, DoCheck, OnDestroy } from '@angular/core';

import { ProductService } from '../product.service';
import { Title, Meta } from '@angular/platform-browser';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-aptitude-questions',
  templateUrl: './aptitude-questions.component.html',
  styleUrls: ['./aptitude-questions.component.css']
})
export class AptitudeQuestionsComponent implements OnInit, DoCheck, OnDestroy {


  image = '../../assets/img/logo.png';
  description = ``;
  keyword = ``;
  commonName;
  commomService;
  apiService;
  name = ``;
  questionList = [];
  currentQ;
  p: number = 1;
  loader = false;
  companyList = [];
  currentData;
  title;
  domain = `https://placementmaster.in`;
  constructor(private productService: ProductService, private meta: Meta,
    private head: Title, public sidebarservice: SidebarService) {
    this.setSeo();
    this.companyList.push({
      name: 'infosys',
      id: '4840c40073774aeb94b8a5b48a714b58'
    },
      {
        name: "cts",
        id: "61a399020c4748c1a100161bd912c3e5"
      },
      {
        name: 'wipro',
        id: '37c86d63d4644c01a7aea3de7cb0db0e'
      },
      {
        name: 'capgemini',
        id: '6fa7ea82a59c4a9d8866290035da13e8'
      },
      {
        name: "accenture",
        id: "5410f5f9b8264210a162b2cb8deb2cf0"
      },
      {
        name: "tcs",
        id : "95ec7993e83243b4b0ee99a7d7d60a09"
      }
    );
    this.checkCurrentData();
  }

  ngOnInit() {
  }
  setSeo() {
    this.name = window.location.pathname.split('/')[2];
    this.title = this.capitalizeFirstLetter(window.location.pathname.split('/')[2]) + ` Aptitude Questions | Placement Master`;
    this.head.setTitle(this.title);
    this.commonName = this.capitalizeFirstLetter(window.location.pathname.split('/')[2]) + " " + this.capitalizeFirstLetter(window.location.pathname.split('/')[1].replace('-', ' '));
    this.description = `Crack ${this.capitalizeFirstLetter(this.name)} Aptitude Questions from Previous year Placement paper. It contains all the topics covered in Aptitude round conducted by ${this.capitalizeFirstLetter(this.name)}`;
    this.meta.updateTag({ name: 'description', content: this.description });
    this.meta.updateTag({ name: 'keywords', content: this.commonName });
    this.meta.updateTag({ name: 'share_url', content: 
    `${this.domain}${window.location.pathname}` });
    this.meta.updateTag({ name: 'og:description', content: this.description });
    this.meta.updateTag({ name: 'og:title', content: this.title });
    this.meta.updateTag({ name: 'twitter:title', content: this.title });
    this.meta.updateTag({ name: 'og:url', content: `${this.domain}${window.location.pathname}`});
  }
  checkCurrentData() {
    this.currentData = this.companyList.find((e) => { return e.name === this.name });
    if (this.currentData) {
      this.currentData = this.currentData.id;
      this.getAptitudeQuestion(this.currentData);
    }
    // this.getAptitudeQuestion(this.currentData);
  }
  ngDoCheck() {
    this.commomService = this.sidebarservice.pathName.subscribe(val => {
      if (val) {
        this.setSeo();
      }
    });
  }
  ngOnDestroy() {
    this.commomService.unsubscribe();
    this.apiService.unsubscribe();
  }

  capitalizeFirstLetter(string) {
    if (string) { 
      string = string.charAt(0).toUpperCase() + string.slice(1);
      string = this.captialName(string);
      return string;
    }
  }

  ngAfterViewInit() {
    this.commomService = this.sidebarservice.pathName.subscribe(val => {
      if (val) {
        setTimeout(() => {
          if (window.location.pathname.includes('aptitude-questions')) {
            this.checkCurrentData();
          }
        }, 200);
      }
    });
  }

  // getAptitudeQuestion(type) {
  //   type = window.location.pathname.split('/')[2];
  //   this.loader = true;
  //   this.questionList = [];
  //   this.productService.getAptitudeQuestion(type).subscribe((res: any) => {
  //     if (res.arr && res.arr.length) {
  //       this.questionList = res.arr;
  //     }
  //     this.loader = false;
  //   }, (err) => {
  //     this.loader = false;
  //   });
  // }

  getAptitudeQuestion(id) {
    this.loader = true;
    this.questionList = [];
    this.p = 1;
    this.apiService = this.productService.getAptitudecommonQuestion(id).subscribe((res: any) => {
      let input = JSON.parse(res.data);
      this.questionList = Object.keys(input).map(i => input[i]);
      this.loader = false;
    }, (err) => {
      this.loader = false;
    });
  }
  viewanswer(item) {
    if (item.q_id === this.currentQ) {
      this.currentQ = null;
    } else {
      this.currentQ = item.q_id;
    }
  }
  onPageChange(page: number) {
    this.p = page;
    var element = document.querySelector("#questionscroll");
    if (element) {
      element.scrollIntoView({ behavior : "smooth"});
    }
  }
  buy() {
    window.open('https://placementmaster.myinstamojo.com/product/1021732/download-service-company-question-paper-coll/');
  }
  captialName(name) {
    return name.length <= 3 ? name.toUpperCase() : name;
  }
}
