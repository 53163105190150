import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  api_url = environment.HOST.link;

  constructor(private http: HttpClient) {


  }
  getActiveApi() {
    return this.http.get(this.api_url + '/', { responseType: 'text' });
  }
  
  jsMinifier(payload) {
    return this.http.post(this.api_url + `/jsminify`, payload, { responseType: 'text' });
  }
  getAptitudeQuestion(url) {
    return this.http.get(this.api_url + `questions/read?type=${url}`);
  }
  getAptitudecommonQuestion(id) {
    if (id) return this.http.get(`https://jsoneditoronline.herokuapp.com/v1/docs/${id}`);

  }
}
