import { Component, OnInit, DoCheck, OnDestroy } from '@angular/core';

import { ProductService } from '../product.service';
import { Title, Meta } from '@angular/platform-browser';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-interview-questions',
  templateUrl: './interview-questions.component.html',
  styleUrls: ['./interview-questions.component.css']
})
export class InterviewQuestionsComponent implements OnInit , DoCheck , OnDestroy {

  image = '../../assets/img/angular.png';
  description = ``;
  keyword = ``;
  commonName;
  commomService;
  apiService;
  companyList = [];
  currentData;
  loader = false;
  questionList = [];
  name;
  title;
  domain = `https://placementmaster.in`;
  constructor(private productService: ProductService, private meta: Meta,
    private head: Title, public sidebarservice: SidebarService) {
    this.setSeo();
    this.companyList.push({
      name: 'infosys',
      id: '42ccd83177cc4d4f8c40c54024e45aa1'
    },
      {
        name: "wipro",
        id: "4e9d3ae5de77408a8d3fbb3a4d51ca1f"
      },
      {
        name : "tcs",
        id: "d1eacdca34e643528761b1a3ed87c2ba"
      },
      {
        name : "accenture",
        id : "c3e8c4a409e346fe9e1986b03a785a15"
      },
      {
        name : "cts",
        id : "f5a8ddd2385c4a5db9a2f23a323a766b"
      },
      {
        name : "capgemini",
        id : "9533dc026b9d48b9a559b60f717033b4"
      },
    );
    this.checkCurrentData();
  }

  ngOnInit() {
  }
  setSeo() {
    this.name = window.location.pathname.split('/')[2];

    this.title = this.capitalizeFirstLetter(window.location.pathname.split('/')[2]) + ` Interview Questions | Placement Master`;

    this.commonName = window.location.pathname.split('/')[2] + " " + window.location.pathname.split('/')[1].replace('-', ' ');

    this.description = `Prepare ${this.capitalizeFirstLetter(this.name)} Interview Questions from Previous year Placement paper. Crack the HR round by preparing frequently asked Interview questions by HR`;
    this.head.setTitle(this.title);
    this.meta.updateTag({ name: 'description', content: this.description });
    this.meta.updateTag({ name: 'keywords', content: this.commonName });
    this.meta.updateTag({ name: 'share_url', content: 
    `${this.domain}${window.location.pathname}` });
    this.meta.updateTag({ name: 'og:description', content: this.description });
    this.meta.updateTag({ name: 'og:title', content: this.title });
    this.meta.updateTag({ name: 'twitter:title', content: this.title });
    this.meta.updateTag({ name: 'og:url', content: `${this.domain}${window.location.pathname}`});
  }
  ngDoCheck() {
   this.commomService = this.sidebarservice.pathName.subscribe(val => {
      if (val) {
        this.setSeo();
      }
    });
  }
  ngOnDestroy() {
    this.commomService.unsubscribe();
    this.apiService.unsubscribe();
  }
  ngAfterViewInit() {
    this.commomService = this.sidebarservice.pathName.subscribe(val => {
      if (val) {
        setTimeout(() => {
            if (window.location.pathname.includes('interview-questions')) {
              this.checkCurrentData();
            }
        }, 200);
      }
    });
  }
  checkCurrentData() {
    this.currentData = this.companyList.find((e) => { return e.name === this.name });
    if (this.currentData) {
      this.currentData = this.currentData.id;
      this.getinterviewQuestion(this.currentData);
    }
  }

  getinterviewQuestion(id) {
    this.loader = true;
    this.questionList = [];
    this.apiService = this.productService.getAptitudecommonQuestion(id).subscribe((res: any) => {
      let input = JSON.parse(res.data);
      this.questionList = input;
      this.loader = false;
    }, (err) => {
      this.loader = false;
    });
  }

  capitalizeFirstLetter(string) {
    if (string) { 
      string = string.charAt(0).toUpperCase() + string.slice(1);
      string = this.captialName(string);
      return string;
    }
  }

  buy() {
    window.open('https://placementmaster.myinstamojo.com/product/1021732/download-service-company-question-paper-coll/');
  }
  captialName(name) {
    return name.length <= 3 ? name.toUpperCase() : name;
  }
}
