import { Component } from '@angular/core';
import { SidebarService } from './sidebar/sidebar.service';

import { MetaService } from './metaServices';
import { ProductService } from './product.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Placement Master';
  constructor(public sidebarservice: SidebarService , private metaService : MetaService , 
    private productService: ProductService) { }
  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }
  toggleBackgroundImage() {
    this.sidebarservice.hasBackgroundImage = !this.sidebarservice.hasBackgroundImage;
  }
  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }
  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.productService.getActiveApi().subscribe((res) => {
    });
  }
}
